<template>
  <main class="h-screen bg-white text-black grid justify-center items-center py-12 md:py-12">
    <div class="border rounded border-primary-dark | shadow-2xl | sm:text-lg mb-6 pb-6">
      <!-- logo bit  -->
      <div class="px-12 py-8">
        <img width="280" class="mx-auto block mb-2" src="@/assets/logo.svg" alt="Visual Synapses Logo">
        <h2 class="text-center text-4xl font-light"><span class="font-bold">Visual</span>Synapses</h2>
      </div>

      <!-- name bit  -->
      <div class="bg-black text-white px-12 py-8">
        <h3 class="font-bold text-4xl -mb-1">{{name}}</h3>
        <p>{{position}}</p>
      </div>

      <!-- info bit -->
      <div class="px-4 md:px-12 py-8">
        <p><span class="text-outline text-white text-xl tracking-wider inline-block w-11">w:</span>
          https://www.visualsynaspes.co.uk</p>
        <p><span class="text-outline text-white text-xl tracking-wider inline-block w-11">e:</span>
          hello@visualsynapses.co.uk</p>
      </div>
    </div>

    <div class="text-center text-lg px-4 md:px-12 pt-12 pb-8">
      <a href="mailto:hello@visualsynapses.co.uk"
        class="px-12 py-6 rounded-lg w-full flex justify-center items-center | bg-primary text-white">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16.8" viewBox="0 0 21 16.8">
          <path id="Icon_material-email" data-name="Icon material-email"
            d="M21.9,6H5.1A2.1,2.1,0,0,0,3.011,8.1L3,20.7a2.106,2.106,0,0,0,2.1,2.1H21.9A2.106,2.106,0,0,0,24,20.7V8.1A2.106,2.106,0,0,0,21.9,6Zm0,4.2-8.4,5.25L5.1,10.2V8.1l8.4,5.25L21.9,8.1Z"
            transform="translate(-3 -6)" fill="#fefeff" />
        </svg>
        <span class="ml-4">Send me an email!</span>
      </a>
    </div>
    <div class="text-center text-lg px-4 md:px-12 pb-24">
      <a download :href="pdf"
        class="px-12 py-6 rounded-lg w-full flex justify-center items-center | bg-primary text-white">

        <svg xmlns="http://www.w3.org/2000/svg" width="16.983" height="22.644" viewBox="0 0 16.983 22.644">
          <path id="Icon_awesome-file-download" data-name="Icon awesome-file-download"
            d="M9.907,6.015V0H1.061A1.059,1.059,0,0,0,0,1.061V21.583a1.059,1.059,0,0,0,1.061,1.061h14.86a1.059,1.059,0,0,0,1.061-1.061V7.076H10.968A1.065,1.065,0,0,1,9.907,6.015Zm3.381,9.348L9.024,19.6a.754.754,0,0,1-1.063,0L3.7,15.363a.707.707,0,0,1,.5-1.21H7.076V10.614a.707.707,0,0,1,.708-.708H9.2a.707.707,0,0,1,.708.708v3.538h2.883A.708.708,0,0,1,13.288,15.363ZM16.673,4.644,12.344.31A1.061,1.061,0,0,0,11.592,0h-.27V5.661h5.661v-.27A1.058,1.058,0,0,0,16.673,4.644Z"
            fill="#fefeff" />
        </svg>
        <span class="ml-4">Download PDF!</span>
      </a>
    </div>

  </main>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        currentRoute: window.location.pathname.toLowerCase()
      }
    },
    computed: {
      pdf() {
        if (this.currentRoute === '/dom' || this.currentRoute === '/dom-libert') {
          return './visual_synapses_dom_libert.pdf'
        } else {
          return './visual_synapses_laxmi_owczarek.pdf'
        }
      },
      name() {
        if (this.currentRoute === '/dom' || this.currentRoute === '/dom-libert') {
          return 'Dom Libert'
        } else {
          return 'Laxmi Owczarek'
        }
      },
      position() {
        if (this.currentRoute === '/dom' || this.currentRoute === '/dom-libert') {
          return 'Co-founder & Developer'
        } else {
          return 'Co-founder & Designer'
        }
      }
    }
  }
</script>